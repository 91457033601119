/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard reactstrap
// components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Table,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Modal,
    Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import Pagination from "components/Pagination/Pagination.js";
import { getApi } from "../../services/api/index";
import { toast } from 'react-toastify';
import axios from 'axios';
import apiConfig from '../../services/api/config';

// import _ from 'lodash';
class Icons extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        results: [], 
        totalPages: 0, 
        totalResults: 0, 
        exhibitorModal: false,
        productsModal: false,
        currentPageNo: 1,
        pageLimit:10, 
        query: '',
        categories: [],
        category_selected: {id:1, sub_category_parent:[]},
        sub_category_selected:1,
        selectedExhibitor:{},
      };
      this.cancel = '';
      this.changeCategoryFilter = this.changeCategoryFilter.bind(this);
      this.changeSubCategoryFilter = this.changeSubCategoryFilter.bind(this);
    }
    toggleModal = (state, exhibitor) => {
      this.setState({
        [state]: !this.state[state],
        selectedExhibitor: exhibitor,
      });
    };

    getPageCount = (total, denominator) => {
      const divisible	= 0 === total % denominator;
      const valueToBeAdded = divisible ? 0 : 1;
      return Math.floor( total/denominator ) + valueToBeAdded;
    };
    componentDidMount() {
      this.categoriesGet();
      this.bindData(this.state.currentPage, this.state.query, this.state.sub_category_selected);
    }

    onPageChanged = data => {
      this.setState({currentPageNo: data.currentPage});
      this.bindData(data.currentPage, this.state.query, this.state.sub_category_selected);
    };

    categoriesGet = () => {
      const searchUrl = `categories/`;
      getApi(searchUrl)
        .then(response => {
          this.setState({
            categories: response.data,
            category_selected: response.data[0],
          });
        })
        .catch( error => {
          toast.error("Hubo un error al cargar los Datos!")
        })
    }

    bindData = (updatedPageNo=1, query, category_id) =>{
      const params = {page: updatedPageNo, search:query, sub_category:category_id}
      const searchUrl = `companies/`;

      if(this.cancel) {
        this.cancel.cancel();
      }
      this.cancel = axios.CancelToken.source();

      getApi(searchUrl, {},{}, params, this.cancel.token)
        .then(response => {
          const total = response.data.count;
          const totalPagesCount = this.getPageCount(total, 10);
          this.setState({
            results: response.data.results,
            totalResults: total,
            totalPages: totalPagesCount,
            currentPageNo: updatedPageNo,
            sub_category_selected: category_id,
          })
        })
        .catch( error => {
          if (!axios.isCancel(error)) {
            toast.error("Hubo un error al cargar los datos!")
          }
        })
      
    }
    handleOnInputChange = (event) => {
      const query = event.target.value;
      this.setState({query}, () => {
        this.bindData(1, query, this.state.sub_category_selected);
      });
    };

    listRegion = (region, max = 3) => {
      const each_regions = region.split(",");
      if (max === null){
        max = each_regions.length
      }
      if (Object.keys(each_regions).length && each_regions.length) {
        return (
          <ul className="text-left pl-3 pr-0">
            {each_regions.slice(0, max).map(result => {
              return (
                <li key={Math.random()}>{result}</li>
              )
            }
          )}
          </ul>
          
        )
      }
      return null;
    }
    changeCategoryFilter(event) {
      const { categories } = this.state;
      const id_category = Number(event.target.value);
      let category_take = null;
      for (let i = 0; i < categories.length; i++) {
          if(id_category === categories[i].id){
            category_take = categories[i]
            this.setState({category_selected:category_take});
            break;
          }
      }
      this.bindData(1, this.state.query, category_take.sub_category_parent[0].id);
    }

    changeSubCategoryFilter(event){
      const sub_category_id = Number(event.target.value);
      this.bindData(1, this.state.query, sub_category_id);
    }

    render() {
      const { results, totalResults, query, categories, category_selected, selectedExhibitor } = this.state;
        return ( <> <Header/>
        < Container className = " mt--7" fluid > {/* Table */
        } < Row > <div className=" col">
            <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                    <h3 className=" mb-0">Exhibidores</h3>
                    <label htmlFor="exampleFormControlSelect1">Filtrar Categorías - Sub Categoría</label>
                    <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                      <FormGroup className="mb-0 w-100">
                        <Input id="category" type="select" className="search-tables mr-2 search_text select_search" onChange={this.changeCategoryFilter}>
                          {categories.map((category, i) => 
                            <option key={i} value={category.id}>{category.name}</option>
                          )}
                        </Input>
                        <Input id="sub_category" type="select" className="search-tables mx-2 search_text select_search" onChange={this.changeSubCategoryFilter}>
                          {category_selected.sub_category_parent.map((sub_category, i) => 
                              <option key={i} value={sub_category.id}>{sub_category.name}</option>
                            )}
                        </Input>
                        <InputGroup className="input-group-alternative search-tables ml-auto">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="search_text">
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Buscar" type="text" value={query} className="search_text" onChange={this.handleOnInputChange}/>
                        </InputGroup>
                      </FormGroup>
                  </Form>
                </CardHeader>
                <CardBody>
                    <Row className=" icon-examples">
                        <Table className="align-items-center" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">
                                      Todos
                                        <div className="custom-control custom-checkbox">
                                            <label className="custom-control-label" htmlFor="customCheck1">
                                            </label>
                                            <input className="custom-control-input" id="customCheck1" type="checkbox"/>
                                        </div>
                                    </th>
                                    <th scope="col">Categoría</th>
                                    <th scope="col">Sub Categoría</th>
                                    <th scope="col">Seller</th>
                                    <th scope="col">N° de Productos</th>
                                    <th scope="col">Tipos Productos</th>
                                    <th scope="col" className="images">Imagenes</th>
                                    <th scope="col">Grupo</th>
                                    <th scope="col">Cotización Enviada</th>
                                    <th scope="col">Cotización Recibida</th>
                                    <th scope="col">Ultima Gestión</th>
                                    <th scope="col"/>
                                </tr>
                            </thead>
                            <tbody>
                            { results.map((exhibitor, i) => 
                                <tr key={exhibitor.id}>
                                <td>
                                    <div className="custom-control custom-checkbox mb-4">
                                        <input className="custom-control-input" id={`check_${exhibitor.id}`} type="checkbox"/>
                                        <label className="custom-control-label" htmlFor={`check_${exhibitor.id}`}></label>
                                    </div>
                                </td>
                            <td>{exhibitor.company_publication[0].sub_category.category.name}</td>
                                <td>{exhibitor.company_publication[0].sub_category.name}</td>
                                <td>{exhibitor.name}</td>
                                <td>
                                    <div className="d-flex justify-content-center">
                                      <span className="mr-2">{exhibitor.company_publication.length}</span>
                                    </div>
                                </td>
                            <td>
                              {this.listRegion(exhibitor.region)}
                            </td>
                                <td className="d-flex flex-row images_products">
                                    <div className="avatar-group">
                                        <a
                                            className="avatar"
                                            href="#pablo"
                                            id="tooltip804044742"
                                            onClick={e => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={exhibitor.company_image_labels[0].image1}/>
                                        </a>
                                    </div>
                                    <div className="avatar-group">
                                        <a
                                            className="avatar"
                                            href="#pablo"
                                            id="tooltip804044742"
                                            onClick={e => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={exhibitor.company_image_labels[0].image2}/>
                                        </a>
                                    </div>
                                    <div className="avatar-group">
                                        <a
                                            className="avatar"
                                            href="#pablo"
                                            id="tooltip804044742"
                                            onClick={e => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={exhibitor.company_image_labels[0].image3}/>
                                        </a>
                                    </div>
                                </td>
                            <td>{exhibitor.company_publication[0].group.replace("group_", "")}</td>
                            <td>{exhibitor.website ? "Si":"No"}</td>
                            <td>{exhibitor.website ? "Si":"No"}</td>
                            <td>{exhibitor.enterprise_type}</td>
                                <td className="text-left">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-icon-only text-light"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={e => e.preventDefault()}>
                                            <i className="fas fa-ellipsis-v"/>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem href="#pablo" onClick={() => this.toggleModal("exhibitorModal", exhibitor)}>
                                                Ver Exhibidor
                                            </DropdownItem>
                                            <DropdownItem href="#pablo" onClick={() => this.toggleModal("productsModal", exhibitor)}>
                                                Ver Productos
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                          )}
                                
                            </tbody>
                        </Table>
                        <div className="d-flex flex-row justify-content-end py-4 align-items-center w-100">
                        <Pagination key={totalResults} totalRecords={totalResults} pageLimit={this.state.pageLimit} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                      </div>
                    </Row>
                </CardBody>
            </Card>
        </div> </Row>
        </Container > 
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exhibitorModal}
          toggle={() => this.toggleModal("exhibitorModal", {})}
        >
          <div className="modal-header">
            <div>
              <h4 className="modal-title mb-2" id="exampleModalLabel">{selectedExhibitor.name}</h4>
              <h5><strong>Tipo de Empresa: </strong>{selectedExhibitor.enterprise_type}</h5>
              <h5><strong>Naturaleza: </strong>{selectedExhibitor.nature}</h5>
              <h5><strong>Página Web: </strong>{selectedExhibitor.website}</h5>
              <h5><strong>Contacto: </strong>{selectedExhibitor.company_parent === undefined ? "-":selectedExhibitor.company_parent[0].name}</h5>
              <h5><strong>Correo: </strong>{selectedExhibitor.email}</h5>
              <h5><strong>Teléfono: </strong>{selectedExhibitor.company_parent === undefined ? "-":selectedExhibitor.company_parent[0].mobile}</h5>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exhibitorModal", {})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {/* Modal Body Exhibitors */}
            <div className="py-4 d-flex justify-content-between">
              <img className="exhibitorImage" width="140" alt="..." src={selectedExhibitor.company_image_labels  === undefined ? "-":selectedExhibitor.company_image_labels[0].image1}/>
              <img className="exhibitorImage" width="140" alt="..." src={selectedExhibitor.company_image_labels  === undefined ? "-":selectedExhibitor.company_image_labels[0].image2}/>
              <img className="exhibitorImage" width="140" alt="..." src={selectedExhibitor.company_image_labels  === undefined ? "-":selectedExhibitor.company_image_labels[0].image3}/>
            </div>
            <h4 id="exampleModalLabel">Información Básica</h4>
            <Table striped>
            <tbody>
              <tr>
                <td>Productos: </td>
                <td>{selectedExhibitor.product}</td>
              </tr>
              <tr>
                <td>Area: </td>
                <td>{selectedExhibitor.country_area}</td>
              </tr>
              <tr>
                <td>Dirección: </td>
                <td>{selectedExhibitor.address}</td>
              </tr>
              <tr>
                <td>Escala de Empresa: </td>
                <td>{selectedExhibitor.scale}</td>
              </tr>
              <tr>
                <td>Capital: </td>
                <td>{selectedExhibitor.capital}</td>
              </tr>
              <tr>
                <td>Año de establecimiento: </td>
                <td>{selectedExhibitor.startyear}</td>
              </tr>
              <tr>
                <td>Tipos de Productos: </td>
                <td>{selectedExhibitor.region === undefined ? "-":this.listRegion(selectedExhibitor.region, null)}</td>
              </tr>
            </tbody>
          </Table>

          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exhibitorModal", {})}
            >
              Cerrar
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.productsModal}
          toggle={() => this.toggleModal("productsModal", {})}
        >
          <div className="modal-header">
            <h4 className="modal-title mb-2" id="exampleModalLabel">{selectedExhibitor.name}</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("productsModal", {})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <h4 id="exampleModalLabel">Productos</h4>
            <Table bordered>
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Precio Min</th>
                <th>Precio Max</th>
                <th>Cant Minima de Orden</th>
              </tr>
            </thead>
            <tbody>
            { selectedExhibitor.company_publication === undefined ? null: selectedExhibitor.company_publication.map((product, i) => 
              <tr key={product.id}>
                <td><img width="60" alt="..." src={`${apiConfig.url}${product.image}`}/></td>
                <td>{product.name}</td>
                <td className="text-center">{product.min_price}</td>
                <td className="text-center">{product.max_price}</td>
                <td className="text-center">{product.moq}</td>
              </tr>
            )}
              
            </tbody>
          </Table>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("productsModal", {})}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
        </>);
    }
}

export default Icons;
