import axios from 'axios';
import apiConfig from './config';

export const getApi = (endPoint, headers = {"Content-Type": "application/json"}, data = {}, params = {}, cancelToken = null) => {
    // const accessToken = sessionSelectors.get().tokens.access.value;
    const config = {
        method: 'get',
        url: `${apiConfig.url}/${endPoint}`,
        data: data,
        headers: headers,
        params: params,
        cancelToken: cancelToken,
      };
    return axios(config)
    
};

export const postApi = (endPoint, headers = {"Content-Type": "application/json"}, data = {}, params = {}) => {
    // const accessToken = sessionSelectors.get().tokens.access.value;
    const config = {
        method: 'post',
        cache: "no-cache",
        url: `${apiConfig.url}/${endPoint}`,
        data: data,
        headers: headers,
        params: params,
      };
  
	axios(config)
      .then(res => {
          return res;
      })
};

export const putApi = (endPoint, headers = {"Content-Type": "application/json"}, data = {}, params = {}) => {
    // const accessToken = sessionSelectors.get().tokens.access.value;
    const config = {
        method: 'put',
        cache: "no-cache",
        url: `${apiConfig.url}/${endPoint}`,
        data: data,
        headers: headers,
        params: params,
      };
  
	axios(config)
      .then(res => {
          return res;
      })
};

export const patchApi = (endPoint, payload = {}, headers = {"Content-Type": "application/json"}, data={}, params = {}) => {
    // const accessToken = sessionSelectors.get().tokens.access.value;
    const config = {
        method: 'patch',
        cache: "no-cache",
        url: `${apiConfig.url}/${endPoint}`,
        data: data,
        headers: headers,
        params: params
      };
  
	axios(config)
      .then(res => {
          return res;
      })
};

export const deleteApi = (endPoint, payload = {}, headers = {"Content-Type": "application/json"}, data={}) => {
    // const accessToken = sessionSelectors.get().tokens.access.value;
    const config = {
        method: 'delete',
        cache: "no-cache",
        url: `${apiConfig.url}/${endPoint}`,
        headers: headers
      };
  
	axios(config)
      .then(res => {
          return res;
      })
};
